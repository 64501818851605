<template>
    <div class="box">
        <div class="main_box">
            <slot></slot>
        </div>
    </div>
</template>

<script>

</script>

<style>
    .box .el-input__icon{
        display: none;
    }
    .box .el-range-separator{
        display: none;
    }
    .box .el-range-input:nth-child(2){
        display: none;
    }
    .box{
        background:#202331;
        position: relative;
        padding-top: 10px;
    }
    .box::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-top:1px solid #73D5F7;
        border-left:1px solid #73D5F7;
        width: 10px;
        height: 10px;
    }
    .box::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-top:1px solid #73D5F7;
        border-right:1px solid #73D5F7;
        width: 10px;
        height: 10px;
        z-index: 999;
    }
    .main_box{
        width: 100%;
        height: 100%;
    }
    .main_box::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom:1px solid #73D5F7;
        border-left:1px solid #73D5F7;
        width: 10px;
        height: 10px;
    }
    .main_box::before{
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom:1px solid #73D5F7;
        border-right:1px solid #73D5F7;
        width: 10px;
        height: 10px;
    }
</style>
<template>
  <div class="Realdata">
    <div class="Realdatabox">
      <div v-if="liveTabActive==1" class="RealdataboxOne">
        <div class="live_time">
          <div class="live_time_left">
            <div class="live_time_week">{{ currentWeek }}</div>
            <div class="live_time_date">{{ currentDate }}</div>
          </div>
          <div class="live_time_right">{{ currentTime }}</div>
        </div>
        <div class="live_temperature">
          <div class="live_temperature_num">{{contentdata.temperature || 0}}°C</div>
          <div class="live_temperature_text">当前温度</div>
        </div>
        <div class="live_more">
          <div @click="boxclick('windSpeed')" class="databox windSpeed">
            <h2>风速</h2>
            <div style="
              border-image: linear-gradient(0deg, #32bcea, #79d3f1) 10 10;
              background: linear-gradient(0deg, #32bcea 0%, #79d3f1 100%);
            ">
              <div>
                <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                  {{ contentdata.windSpeed || 0 }}
                  <span style="font-size: 12px">m/s</span>
                </p>
              </div>
            </div>
          </div>
          <div class="databox humidity" @click="boxclick('humidity')">
            <h2>湿度</h2>
            <div style="
              border-image: linear-gradient(0deg, #f3bb47, #ebb953) 10 10;
              background: linear-gradient(0deg, #f3bb47 2%, #ebb953 100%);
            ">
              <div>
                <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                  {{ contentdata.humidity || 0 }}
                  <span style="font-size: 12px">%</span>
                </p>
              </div>
            </div>
          </div>
          <div @click="boxclick('airPressure')" class="databox airPressure">
            <h2>气压</h2>
            <div style="
              border-image: linear-gradient(0deg, #1dc76c, #2bd97c) 10 10;
              background: linear-gradient(0deg, #1dc76c 0%, #2bd97c 100%);
            ">
              <div>
                <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                  {{ contentdata.airPressure || 0 }}
                  <span style="font-size: 12px">hpa</span>
                </p>
              </div>
            </div>
          </div>
          <div @click="boxclick('windDirection')" class="databox windDirection">
            <h2>风向</h2>
            <div style="
              border-image: linear-gradient(0deg, #56a9fb, #95c8fa) 10 10;
              background: linear-gradient(0deg, #56a9fb 0%, #95c8fa 100%);
            ">
              <div>
                <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                  {{ contentdata.windDirection || 0 }}°
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="live_tips">如需查看更多水文气象信息请切换至十二要素查看</div>
      </div>
      <div v-if="liveTabActive==0" class="Realdataboxtwo">
        <div class="databox temperature" @click="boxclick('temperature')">
          <h2>温度</h2>
          <div style="
              border-image: linear-gradient(0deg, #1f83ef, #62aaf8) 10 10;
              background: linear-gradient(0deg, #1f83ef 0%, #62aaf8 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.temperature || 0 }}
                <span style="font-size: 12px">°C</span>
              </p>
            </div>
          </div>
        </div>
        <div class="databox humidity" @click="boxclick('humidity')">
          <h2>湿度</h2>
          <div style="
              border-image: linear-gradient(0deg, #f3bb47, #ebb953) 10 10;
              background: linear-gradient(0deg, #f3bb47 2%, #ebb953 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.humidity || 0 }}
                <span style="font-size: 12px">%</span>
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('windDirection')" class="databox windDirection">
          <h2>风向</h2>
          <div style="
              border-image: linear-gradient(0deg, #56a9fb, #95c8fa) 10 10;
              background: linear-gradient(0deg, #56a9fb 0%, #95c8fa 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.windDirection || 0 }}°
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('windSpeed')" class="databox windSpeed">
          <h2>风速</h2>
          <div style="
              border-image: linear-gradient(0deg, #32bcea, #79d3f1) 10 10;
              background: linear-gradient(0deg, #32bcea 0%, #79d3f1 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.windSpeed || 0 }}
                <span style="font-size: 12px">m/s</span>
              </p>
            </div>
          </div>
        </div>
        <div class="databox waveDirection" @click="boxclick('waveDirection')">
          <h2>波向</h2>
          <div style="
              border-image: linear-gradient(0deg, #8e5bf5, #b593fd) 10 10;
              background: linear-gradient(0deg, #8e5bf5 0%, #b593fd 98%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.waveDirection || 0 }}°
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('waveHeight')" class="databox waveHeight">
          <h2>波高</h2>
          <div style="
              border-image: linear-gradient(0deg, #6c33d3, #9967f2) 10 10;
              background: linear-gradient(0deg, #6c33d3 0%, #9967f2 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.waveHeight || 0 }}
                <span style="font-size: 12px">m</span>
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('wavePeriod')" class="databox wavePeriod">
          <h2>波周期</h2>
          <div style="
              border-image: linear-gradient(0deg, #ba96ff, #cfb8fc) 10 10;
              background: linear-gradient(0deg, #ba96ff 0%, #cfb8fc 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.wavePeriod || 0 }}
                <span style="font-size: 12px">s</span>
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('airPressure')" class="databox airPressure">
          <h2>气压</h2>
          <div style="
              border-image: linear-gradient(0deg, #1dc76c, #2bd97c) 10 10;
              background: linear-gradient(0deg, #1dc76c 0%, #2bd97c 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.airPressure || 0 }}
                <span style="font-size: 12px">hpa</span>
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('waterTemperature')" class="databox waterTemperature">
          <h2>水温</h2>
          <div style="
              border-image: linear-gradient(0deg, #4683ef, #8fb5f9) 10 10;
              background: linear-gradient(0deg, #4683ef 0%, #8fb5f9 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.waterTemperature || 0 }}
                <span style="font-size: 12px">°C</span>
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('visibility')" class="databox visibility">
          <h2>能见度</h2>
          <div style="
              border-image: linear-gradient(0deg, #11b29f, #4eddcc) 10 10;
              background: linear-gradient(0deg, #11b29f 0%, #4eddcc 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.visibility || 0 }}
                <span style="font-size: 12px">m</span>
              </p>
            </div>
          </div>
        </div>
        <div class="databox flowDirection" @click="boxclick('flowDirection')">
          <h2>流向</h2>
          <div style="
              border-image: linear-gradient(0deg, #9615aa, #c23cd6) 10 10;
              background: linear-gradient(0deg, #9615aa 0%, #c23cd6 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.flowDirection || 0 }}°
              </p>
            </div>
          </div>
        </div>
        <div @click="boxclick('flowSpeed')" class="databox flowSpeed">
          <h2>流速</h2>
          <div style="
              border-image: linear-gradient(0deg, #d45ee0, #f7b9fd) 10 10;
              background: linear-gradient(0deg, #d45ee0 0%, #f7b9fd 100%);
            ">
            <div>
              <p style="margin: auto; word-wrap: break-word; overflow: hidden">
                {{ contentdata.flowSpeed || 0 }}
                <span style="font-size: 12px">cm/s</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hr"></div>

    <div style="width: 100%; height: 500px">
      <apexchart type="area" ref="demoChart" height="540" :options="chartOptions" :series="series" style="margin-top: 10px"></apexchart>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
// import { meteororealdata, meteororealfivedata, meteororealtime } from "@/api/hydrometeor/index.js";

const liveApiName = {
  0: 'getLiveTwelveData',
  1: 'getLiveFiveData'
}
const title = {
  temperature: "气温",
  humidity: "湿度",
  windDirection: "风向",
  windSpeed: "风速",
  airPressure: "大气压",
  waterTemperature: "水温",
  visibility: "能见度",
  waveHeight: "波高",
  waveDirection: "波向",
  wavePeriod: "波周期",
  flowDirection: "流向",
  flowSpeed: "流速",
};
export default {
  name: "Realdata",
  data() {
    return {
      contentdata: [],
      weatherddata: [],
      clickarr: ['temperature', 'humidity'],
      series: [
        {
          name: "温度",
          data: [],
          color: "#127EED",
        },
        {
          name: "湿度",
          data: [],
          color: "#EFB839",
        },
      ],
      chartOptions: {
        chart: {
          height: 400,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          range: 10 - 1,
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: true,
          },
          categories: [],
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
      },
      currentTime: dayjs(new Date()).format("HH:mm:ss"),
      currentWeek: this.$utils.getWeekDate(),
      currentDate: dayjs(new Date()).format("YYYY年MM月DD日"),
    };
  },
  components: {},
  props: ["weather", "maindata", "liveTabActive"],
  watch: {
    liveTabActive() {
      this.clickarr = ['temperature', 'humidity'];
      this.getLiveData();
      this.getLiveDataTrend(false);
    }
  },
  methods: {
    getCurrentTime() {
      this.timer = setInterval(() => {
        const currentTime = dayjs(new Date()).format("HH:mm:ss");
        const currentWeek = this.$utils.getWeekDate();
        const currentDate = dayjs(new Date()).format("YYYY-MM-DD");
        this.currentTime = currentTime;
        this.currentWeek = currentWeek;
        this.currentDate = currentDate;
        this.getLiveData()
      }, 1 * 1000);
    },
    getLiveData() {
      this.$api[liveApiName[this.liveTabActive]]().then((res) => {
        this.contentdata = res.data;
      });
    },
    getLiveDataTrend(isTime, callback) {
      this.$api[liveApiName[this.liveTabActive] + 'Trend']().then((res) => {
        this.weatherddata = res.data;
        let arr = [];
        let dataone = [];
        let datatwo = [];
        res.data.forEach((element) => {
          arr.push(dayjs(element.date).format("HH:mm:ss"));
          dataone.push(element[this.clickarr[0]] || 0);
          datatwo.push(element[this.clickarr[1]] || 0);
        });
        this.series = [{ name: title[this.clickarr[0]], data: dataone }, { name: title[this.clickarr[1]], data: datatwo }];
        this.$refs.demoChart.updateOptions({ xaxis: { categories: arr } });
        if (isTime) {
          this.trendTimer = setTimeout(() => {
            this.getLiveDataTrend(true);
          }, 15 * 1000);
        }
        callback && callback();
      });
    },
    boxclick(name) {
      this.getLiveDataTrend(false, () => {
        for (let i = 0; i < document.getElementsByClassName("databox").length; i++) {
          document.getElementsByClassName("databox")[i].style.cssText = "border-color: #CCCCCC;";
        }
        let dataone = [];
        let datatwo = [];
        let date = [];
        if (this.clickarr.length >= 2) {
          this.clickarr = [];
          this.clickarr.push(name);
        } else {
          this.clickarr.push(name);
        }
        this.weatherddata.forEach((element) => {
          date.push(dayjs(element.date).format("HH:mm:ss"));
          dataone.push(element[this.clickarr[0]] || 0);
          if (this.clickarr.length == 2) {
            datatwo.push(element[this.clickarr[1]] || 0);
          }
        });
        if (this.clickarr.length == 2) {
          this.series = [
            {
              name: title[this.clickarr[0]],
              data: dataone,
            },
            {
              name: title[this.clickarr[1]],
              data: datatwo,
            },
          ];
          document.getElementsByClassName(this.clickarr[1])[0].style.cssText = "border-color: #517CF6;";
        }
        document.getElementsByClassName(this.clickarr[0])[0].style.cssText = "border-color: #517CF6;";
      })
    }
  },
  created() {
    this.getCurrentTime();
    this.getLiveDataTrend(true);
  },
  mounted() { },
  beforeUnmount() {
    this.timer && clearInterval(this.timer);
    this.trendTimer && clearInterval(this.trendTimer)
  },
};
</script>

<style lang="scss">
.Realdata {
  width: 100%;
  height: 100%;
}
.Realdatabox {
  width: 95%;
  /* height: 60%; */
  height: 578px;
  margin: 0 auto;
  display: flex;
}
.Realdataboxtwo {
  width: 100%;
  height: 96%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Realdataboxtwo > div {
  width: 182px;
  height: 166px;
  border: 1px solid #cccccc;
  display: flex;
  position: relative;
  cursor: pointer;
}
.Realdataboxtwo > div > h2 {
  color: #fff;
  font-size: 20px;
  margin: 8px;
  position: absolute;
}
.Realdataboxtwo > div > div {
  margin: auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  display: flex;
  position: relative;
}
.Realdataboxtwo > div > div > div {
  background: #202331;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  margin: auto;
  color: #fff;
  font-size: 30px;
  text-align: center;
  display: flex;
}
.Realdatats::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 25px;
  border: 0.052rem solid transparent;
  border-bottom: 0.13rem solid #94c7fa;
  left: 30px;
  transform: rotate(-35deg);
}
.Realdatatstwo::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100px;
  border: 10px solid transparent;
  border-bottom: 25px solid #a075f9;
  right: 30px;
  transform: rotate(130deg);
}
.Realdatatsthree::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 15px;
  border: 10px solid transparent;
  border-bottom: 25px solid #bb35cf;
  right: 35px;
  transform: rotate(50deg);
}
.RealdataboxOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  .live_time {
    display: flex;
    width: 100%;
    .live_time_left {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      margin-right: 45px;
      .live_time_week {
        margin-bottom: 10px;
      }
      .live_time_date {
      }
    }
    .live_time_right {
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 30px;
    }
  }
  .live_temperature {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #fff;
    font-size: 45px;
    .live_temperature_num {
      margin-bottom: 20px;
    }
    .live_temperature_text {
    }
  }
  .live_more {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    .databox {
      width: 182px;
      height: 166px;
      border: 1px solid #cccccc;
      display: flex;
      position: relative;
      cursor: pointer;
      h2 {
        color: #fff;
        font-size: 20px;
        margin: 8px;
        position: absolute;
      }
      div {
        margin: auto;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        position: relative;
      }
      div > div {
        background: #202331;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        margin: auto;
        color: #fff;
        font-size: 30px;
        text-align: center;
        display: flex;
      }
    }
  }
  .live_tips {
    text-align: center;
    color: #fff;
  }
}
.hr {
  width: 100%;
  height: 10px;
  border-top: 3px dashed #2182ff;
}
.Realdata .apexcharts-legend.apx-legend-position-bottom {
  top: -130px !important;
}
</style>
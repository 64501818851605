<template>
  <div class="Sevendayrecord">
    <div class="sevenday">
      <div v-for="i in timedata" @click="dayclick(i)" style="cursor: pointer;">
        <h5>{{i.datedddd}}</h5>
        <p>{{i.date}}</p>
        <span>{{i.temperature}}℃</span>
      </div>
    </div>

    <div class="sevendayrecorddata">
      <div class="sevendaywrap">
        <apexchart ref="demoChart" type="radialBar" height="140" :options="chartOptions" :series="series"></apexchart>
        <p style="font-size: 14px; color:#127EED;margin-top: 17px;">{{yuandata.avgTempe}}<i style="color: #127EED;">m</i></p>
      </div>
      <div class="sevendaywrap">
        <apexchart ref="demoChart2" type="radialBar" height="140" :options="chartOptions2" :series="series2"></apexchart>
        <p style="font-size: 14px;color: #25BAEB;margin-top:19px;text-align: center;">{{yuandata.maxWindSpeed}}<br> <i style="color: #25BAEB;" v-if="yuandata.maxWindSpeed != null">km/h</i></p>
        <!-- <span style="color: #25BAEB;margin-top: 17px;">km/h</span> -->
      </div>
      <div class="sevendaywrap">
        <apexchart ref="demoChart3" type="radialBar" height="140" :options="chartOptions3" :series="series3"></apexchart>
        <p style="font-size: 14px;color: #2FD3CA;margin-top: 17px;">{{yuandata.miniVisibility}}<i style="color: #2FD3CA;" v-if="yuandata.miniVisibility != null">m</i></p>
        <!-- <span style="color: #2FD3CA;">hpa</span> -->
      </div>
      <div class="sevendaywrap">
        <apexchart ref="demoChart4" type="radialBar" height="140" :options="chartOptions4" :series="series4"></apexchart>
        <p style="color: #1EE681;font-size: 14px;margin-top: 19px; text-align: center;">{{yuandata.avgAirPressure}}<br><i style="color: #1EE681;" v-if="yuandata.avgAirPressure != null">hpa</i></p>
        <!-- <span style="color: #1EE681;font-size: 14px;margin-top: 10px;">hpa</span> -->
      </div>
    </div>

    <div class="sevendaybtn">
      <div>
        平均湿度
        <i></i>
      </div>
      <div>
        最大风速
        <i></i>
      </div>
      <div>
        最近能见度
        <i></i>
      </div>
      <div>
        平均气压
        <i></i>
      </div>
    </div>
  </div>
</template>

<script>
import CircleProgress from "components/CircleProgress";
import { meteorolist, meteoroweekday } from "@/api/hydrometeor/index.js";
import dayjs from "dayjs";
import moment from 'moment'

export default {
  name: "Hydrometeor",
  components: {
    CircleProgress
  },
  data() {
    return {
      yuandata: [],
      series: [80, 80],
      chartOptions: {
        chart: {
          height: 140,
          type: 'radialBar',
        },
        plotOptions: {
          maxWidth: 100,
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: 'Total'
              }
            }
          },
        },
        colors: ['#E0CE0B', '#E0CE0B'],
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: 160,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }]
      },
      timedata: [],
      contentdata: [],

      series2: [80, 80],
      chartOptions2: {
        chart: {
          height: 140,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: 'Total'
              }
            }
          }
        },
        colors: ['#25BAEB', '#25BAEB'],
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: 160,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }]
      },

      series3: [80, 80],
      chartOptions3: {
        chart: {
          height: 140,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: 'Total'
              }
            }
          }
        },
        colors: ['#13DAC5', '#13DAC5'],
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: 160,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }]
      },

      series4: [80, 80],
      chartOptions4: {
        chart: {
          height: 140,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: true,
                label: 'Total'
              }
            }
          }
        },
        colors: ['#1EE681', '#1EE681'],
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: 160,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }]
      },
    };
  },
  computed: {},
  components: {
  },
  props: ['datevalue', 'dayvalue'],
  watch: {
    datevalue(newName, oldName) {
      newName.forEach(element => {
        element.datedddd = this.getWeek(element.date)
        element.date = dayjs(element.date).format("MM/DD")
      });
      this.timedata = newName
      this.contentdata = newName
      // this.series = [newName.humidity,newName.humidity]
      // this.series2 = [newName.windSpeed,newName.windSpeed]
      // this.series3 = []
      // this.series4 = []
      // this.series3
      // this.$refs.demoChart.updateOptions({ xaxis: {
      //     categories: monthtitle,
      // }})
      // meteorolist({
      //     beginTime:dayjs(newName[0]).format("YYYY-MM-DD"),
      //     endTime:dayjs(newName[1]).format("YYYY-MM-DD")
      // }).then(res => {
      //     this.timedata = res.data
      // })
    },
    dayvalue(newName, oldName) {
      this.yuandata = newName
    }
  },
  created() {
    meteorolist({
      beginTime: moment().subtract('days', 6).format('YYYY-MM-DD'),
      endTime: dayjs(new Date()).format("YYYY-MM-DD")
    }).then(res => {
      res.data.forEach(element => {
        element.datedddd = this.getWeek(element.date)
        element.date = dayjs(element.date).format("MM/DD")
      });
      this.timedata = res.data
    })
    meteoroweekday({
      beginTime: moment().subtract('days', 6).format('YYYY-MM-DD'),
      endTime: dayjs(new Date()).format("YYYY-MM-DD")
    }).then(res => {
      this.yuandata = res.data
    })
  },
  methods: {
    moment,
    getWeek(date) { // 参数时间戳
      let week = moment(date).day()
      switch (week) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 0:
          return '周日'
      }
    },
    dayclick(data) {
      this.yuandata.avgTempe = data.humidity
      this.yuandata.avgAirPressure = data.airPressure
      this.yuandata.maxWindSpeed = data.windSpeed
      this.yuandata.miniVisibility = data.windDirection
    }
  },
  mounted() {
  },
};
</script>

<style>
.Sevendayrecord {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
}
.sevenday {
  width: 96%;
  /* height: 80px; */
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: space-between;
  font-size: 12px;
  padding-top: 25px;
  margin: 30px auto;
  margin-bottom: 40px;
}
.sevenday div h5 {
  margin-bottom: 12px;
}
.sevenday div p {
  margin-bottom: 12px;
}
.sevendayrecorddata {
  width: 92%;
  height: 84px;
  margin: -30px auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.sevendayrecorddata > div {
  position: relative;
  width: 84px;
  height: 84px;
  /* border: 2px solid red; */
  /* border-radius: 50%; */
}
.Sevendayrecord .rect {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.Sevendayrecord .recttwo {
  width: 51%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.Sevendayrecord .right {
  right: 0;
}
.Sevendayrecord .left {
  left: 0;
}
.circle {
  /* width: 188%;
    height: 91%; */
  width: 200%;
  height: 95%;
  border: 2px solid #114a89;
  border-radius: 50%;
  position: absolute;
  top: 0;
}
.circletwo {
  width: 133%;
  height: 68%;
  border: 10px solid #114a89;
  border-radius: 50%;
  position: absolute;
  top: 0;
}
.rightcircle {
  /* border-top:5px solid rgb(41,137,216);
    border-right:5px solid rgb(41,137,216); */
  right: 0;
}
.rightcircletwo {
  /* border-top:20px solid rgb(41,137,216);
    border-right:20px solid rgb(41,137,216); */
  right: 0;
}
.leftcircle {
  /* border-top:5px solid rgb(41,137,216);
    border-right:5px solid rgb(41,137,216); */
  left: 0;
}
.leftcircletwo {
  /* border-top:20px solid rgb(41,137,216);
    border-right:20px solid rgb(41,137,216); */
  left: 0;
}
.sevendaynode {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  position: absolute;
  /* left: 12px;
    top: 12px; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sevendaybtn {
  width: 92%;
  height: 38px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.sevendaybtn > div {
  width: 94px;
  height: 100%;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: #232635;
  position: relative;
  font-size: 13px;
  /* margin-top: 25px; */
}
.sevendaybtn > div::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  border-top: 1px solid #1e5dda;
  border-left: 1px solid #1e5dda;
}
.sevendaybtn > div::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  right: 0;
  border-top: 1px solid #1e5dda;
  border-right: 1px solid #1e5dda;
}
.sevendaybtn > div > i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid #1e5dda;
  border-right: 1px solid #1e5dda;
}
.sevendaybtn > div > i::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #1e5dda;
  border-left: 1px solid #1e5dda;
}
.sevendaywrap > p {
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
}
.sevendaywrap > p > i {
  font-size: 14px;
}
.sevendaywrap > span {
  font-size: 13px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, 0%);
}
.dayuan {
  position: relative;
  width: 84px;
  height: 84px;
  background: #114a89;
  border-radius: 50%;
}
.dayuan::before {
  content: "";
  width: 94%;
  height: 94%;
  position: absolute;
  background: #1f1f2b;
  border-radius: 50%;
  top: 2.5px;
  left: 3px;
}
/* .sevendaywrap{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #ccc;
    border-radius: 50%;
} */
/* .circle {
    box-sizing: border-box;
    border: 1px solid #ccc;
    clip: rect(auto, auto, auto, auto);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.circletwo{
    width: 90px;
    height: 90px;
    background: #114A89;
    border-radius: 50%;
    margin: 5px auto;
}
.circlethree{
    width:60px;
    height:60px;
    background: #202331;
    border-radius: 50%;
    position: relative;
    top: 15px;
    margin: 0 auto;
}
.circlevalue{
    position: absolute;
} */
</style>
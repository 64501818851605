<template>
  <div class="Maxwindspeedtren">
    <apexchart
      type="line"
      height="220"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "Maxwindspeedtren",
  data() {
    return {
      series: [
        {
          name: "最高风速",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 220,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          range: 7 - 1,
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: true,
          },
          categories: [],
        },
      },
    };
  },
  computed: {},
  components: {},
  props: ["datevalue"],
  watch: {
    datevalue(newName, oldName) {
      let windspeed = [];
      let statisticsDate = [];
      newName.forEach((element) => {
        windspeed.push(element.maxWindSpeed);
        statisticsDate.push(element.statisticsDate);
      });
      this.series = [
        {
          name: "最高风速",
          data: windspeed,
        },
      ];
      this.chartOptions = { xaxis: { categories: statisticsDate } };
    },
  },
  methods: {},
  mounted() {},
};
</script>

<!--
 * @Author: PC
 * @Date: 2022-01-04 15:34:32
 * @LastEditTime: 2022-01-26 00:02:45
 * @Description: 请您输入文件描述
-->
<template>
  <div class="Minvisibilitytrend">
    <apexchart
      type="line"
      height="230"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
export default {
  name: "Minvisibilitytrend",
  data() {
    return {
      series: [
        {
          name: "能见度",
          type: "area",
          data: [],
        },
        {
          name: "波高",
          type: "column",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "line",
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: [],
        markers: {
          size: 0,
        },
        xaxis: {
          range: 7 - 1,
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: true,
          },
          categories: [],
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
            },
          },
        },
      },
    };
  },
  computed: {},
  components: {},
  props: ["datevalue"],
  watch: {
    datevalue(newName, oldName) {
      let njd = [];
      let bg = [];
      let categories = [];
      newName.forEach((element) => {
        njd.push(element.minVisibility);
        bg.push(element.maxWaveHeight);
        categories.push(element.statisticsDate);
      });
      this.series = [
        {
          name: "能见度",
          type: "area",
          data: bg,
        },
        {
          name: "波高",
          type: "column",
          data: njd,
        },
      ];
      this.chartOptions = { labels: categories };
    },
  },
  methods: {},
  mounted() {},
};
</script>

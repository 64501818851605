<template>
    <div class="Proportionweather">
        <div class="Proportionweatherleft">
            <apexchart type="radar" height="200" :options="chartOptions" :series="series" width="320"></apexchart>
        </div>
        <div class="Proportionweatherright">
            <div class="Proportionclass">
                <div>
                    <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #127EED; border: 1px solid #fff;"></div>
                    <p style="color: #fff;">温度</p>
                </div>
                <div>
                    <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #EFB839; border: 1px solid #fff;"></div>
                    <p style="color: #fff;">湿度</p>
                </div>
                <div>
                    <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #25BAEB; border: 1px solid #fff;"></div>
                    <p style="color: #fff;">风速</p>
                </div>
                <div>
                    <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #13DAC5; border: 1px solid #fff;"></div>
                    <p style="color: #fff;">能见度</p>
                </div>
                <div>
                    <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #6C33D3; border: 1px solid #fff;"></div>
                    <p style="color: #fff;">波高</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.Proportionweather{
    width: 100%;
    height: 100%;
}
.Proportionweather .apexcharts-text {
    display: none;
}
.Proportionweather .apexcharts-toolbar{
    display: none;
}
.Proportionweatherleft{
    width: 70%;
    float: left;
    position: relative;
    top: 30px;
}
.Proportionweather .Proportionweatherright{
    float: right;
    width: 30%;
    height:70%;
    display: flex;
    margin-top: 10px
}
.Proportionweather .Proportionclass{
    width: 70%;
    height: 70%;
    margin: auto;
}
.Proportionweather .Proportionclass>div{
    width: 100%;
    height: 20%;
    font-size: 17px;
    margin-bottom: 10px;
}
</style>

<script>
export default {
    name: "Hydrometeor",
    data() {
      return {
        datas: '',
        series: [{
          name: 'Series 1',
          data: [20, 100, 40, 30, 50],
        }],
        chartOptions: {
          chart: {
            height: 200,
            type: 'radar',
          },
          plotOptions: {
            radar: {
              size: 70,
              polygons: {
                strokeColors: '#333333'
              }
            }
          },
          colors: ['#127EED','red'],
          markers: {
            size: 4,
            strokeColor: ['#127EED','red'],
            strokeWidth: 1,
          },
         
          xaxis: {
            categories: ['最大温度>26℃','最大湿度>70%','最大风速>40km/h','最近能见度<10m','最大波高>3m']
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              formatter: function(val, i) {
                if (i % 2 === 0) {
                  return val
                } else {
                  return ''
                }
              }
            }
          }
        },
      };
    },
    computed: {},
    components: {
    },
    props: ['weatherdata'],
    watch: {
        weatherdata(newName, oldName) {
            this.series = [{
                name: '特殊天气',
                data: [newName.temperature, newName.humidity, newName.windSpeed, newName.visibility, newName.waveHeight]
            }]
        }
    },
    methods: {
    },
    mounted() {
    },
 };
</script>
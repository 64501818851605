<template>
  <div class="Proportioncategories">
    <div class="Proportionweatherleft">
      <apexchart ref="demochart" type="pie" width="320" :options="chartOptions" :series="series" height="220" style="float: left;"></apexchart>
    </div>
    <div class="Proportionweatherrighttwo">
      <div class="Proportionclass">
        <div>
          <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #127EED; border: 1px solid #fff;"></div>
          <p style="color: #fff;">高温告警</p>
        </div>
        <div>
          <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #25BAEB; border: 1px solid #fff;"></div>
          <p style="color: #fff;">风速告警</p>
        </div>
        <div>
          <div style="margin:2px 8px; float: left; width: 5px; height: 5px; border-radius:50%; background: #EFB839; border: 1px solid #fff;"></div>
          <p style="color: #fff;">湿度告警</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.Proportioncategories .apexcharts-text {
  display: none;
}
.Proportioncategories .apexcharts-toolbar {
  display: none;
}

.Proportioncategories .apexcharts-legend {
  display: none !important;
}
.Proportioncategories {
  width: 100%;
  height: 100%;
}
.Proportioncategories .Proportionweatherleft {
  width: 70%;
  height: 100%;
  float: left;
  position: relative;
  top: 10px;
}
.Proportionweatherrighttwo {
  float: right;
  width: 30%;
  height: 270px;
  display: flex;
}
.Proportionclass {
  width: 70%;
  height: 50%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.Proportionclass > div {
  width: 100%;
  height: 20%;
}
</style>

<script>
import { meteorotype } from "@/api/hydrometeor/index.js"
import dayjs from "dayjs";
export default {
  name: "Proportioncategories",
  data() {
    return {
      typedata: [],
      series: [0, 0, 0],
      chartOptions: {
        labels: ['高温告警占比', '风速告警占比', '湿度告警占比'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 250
            }
          }
        }]
      },
    };
  },
  components: {
  },
  props: ['datevalue'],
  watch: {
    datevalue() {
      const { TEMPERATURE, WIND_SPEED, HUMIDITY } = this.datevalue;
      this.series = [TEMPERATURE, WIND_SPEED, HUMIDITY];
    }
  },
  methods: {
    goBack() { },
  },
  beforeUnmount() { },
};
</script>
<template>
  <div class="Hydrometeor">
    <HomeHead :isHome="true" />
    <div class="back_row">
      <!-- <Navigation :isNavigation="false" /> -->
      <div class="navigation">
        <el-button class="navigation-btn" @click="returnclick">
          <span class="navigation-btn-text">返回主页面</span>
        </el-button>
      </div>
    </div>
    <div class="EventBox">
      <div style="width: 480px; height: 100%">
        <Box style="width: 100%; height: 332px; margin-bottom: 10px">
          <!-- height: 332px; -->
          <Boxtitle>
            <div>
              <h4>七日气象记录</h4>
              <el-date-picker v-model="value1" type="daterange" :start-placeholder="sevendaytime"
                :end-placeholder="sevendaytime" @change="changetime"> </el-date-picker>
            </div>
          </Boxtitle>
          <!-- <CircleProgress :id="'circle1'" :circleWidth="40" :radius="7" :progress="30" :isAnimation="true" :backgroundColor="'#E9E9E9'" :barColor="'#FF4F4F'" /> -->
          <Sevendayrecord :datevalue="sevenddata" :dayvalue="dayvalue" />
        </Box>
        <Box style="width: 100%; height: 270px; margin-bottom: 10px; overflow: hidden">
          <Boxtitle>
            <div>
              <h4>特殊天气占比</h4>
              <el-date-picker v-model="value2" type="daterange" :start-placeholder="Proportionweathertext"
                :end-placeholder="Proportionweathertext" @change="changetimetwo" />
            </div>
          </Boxtitle>
          <Proportionweather :weatherdata="weatherdata" />
        </Box>
        <Box style="width: 100%; height: 270px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>告警类别占比</h4>
              <el-date-picker v-model="value3" type="daterange" :start-placeholder="Proportioncategoriestext"
                :end-placeholder="Proportioncategoriestext" @change="changetimethree" />
            </div>
          </Boxtitle>
          <Proportioncategories :datevalue="typedata" />
        </Box>
      </div>
      <div style="width: 830px; height: 100%">
        <Box style="width: 100%; height: 910px; margin-bottom: 10px">
          <Boxtitle>
            <div class="live_title">
              <h4>实时数据</h4>
              <div class="live_tab">
                <div :class="{ live_tab_item: true, live_tab_active: liveTabActive == item.id }"
                  v-for="(item,index) in liveTab" :key={index} @click="onLiveTab(item,index)">{{item.name}}</div>
              </div>
            </div>
          </Boxtitle>
          <Realdata :weather="weather" :maindata="maindata" :liveTabActive="liveTabActive" />
        </Box>
      </div>
      <div style="width: 480px; height: 100%">
        <el-date-picker v-model="value4" type="daterange" :start-placeholder="eventlogtimetext"
          :end-placeholder="eventlogtimetext" @change="changetimefour" class="eventlogalltime" />
        <Box style="width: 100%; height: 276px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>温度趋势</h4>
              <!-- <button>选择时间</button> -->
            </div>
          </Boxtitle>
          <Temperaturetrend :datevalue="timedata" />
        </Box>
        <Box style="width: 100%; height: 276px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>最高风速趋势</h4>
              <!-- <button>选择时间</button> -->
            </div>
          </Boxtitle>
          <Maxwindspeedtren :datevalue="timedata" />
        </Box>
        <Box style="width: 100%; height: 276px; margin-bottom: 10px">
          <Boxtitle>
            <div>
              <h4>最近能见度/最大波高趋势</h4>
              <!-- <button>选择时间</button> -->
            </div>
          </Boxtitle>

          <Minvisibilitytrend :datevalue="timedata" />
        </Box>
      </div>
    </div>
    <!-- <img :src="require('@/assets/image/pages/Hydrometeor/天气二级页面.png')" alt="" style="width: 100%;"> -->
  </div>
</template>

<script>
import HomeHead from "components/HomeHead";
import Box from "components/Box";
import Boxtitle from "components/Boxtitle";
import Sevendayrecord from "components/Hydrometeor/Sevendayrecord";
import Proportionweather from "components/Hydrometeor/Proportionweather";
import Proportioncategories from "components/Hydrometeor/Proportioncategories";
import Realdata from "components/Hydrometeor/Realdata";
import Temperaturetrend from "components/Hydrometeor/Temperaturetrend";
import Maxwindspeedtren from "components/Hydrometeor/Maxwindspeedtren";
import Minvisibilitytrend from "components/Hydrometeor/Minvisibilitytrend";
import CircleProgress from "components/CircleProgress";
import dayjs from "dayjs";
import { ElMessage } from "element-plus";
import { meteorovisibility, meteorolist, meteoroweekday, meteorotype, meteororealtime, meteororealdata, meteororatio } from "@/api/hydrometeor/index.js";

export default {
  name: "Hydrometeor",
  data() {
    return {
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      sevendaytime: "选择时间",
      Proportionweathertext: "选择时间",
      Proportioncategoriestext: "选择时间",
      eventlogtimetext: "选择时间",
      timedata: "",
      sevenddata: "",
      dayvalue: "",
      typedata: [],
      weather: [],
      weatherdata: [],
      maindata: [],
      liveTab: [
        {
          id: 0,
          name: '十二要素'
        },
        {
          id: 1,
          name: '五要素'
        }
      ],
      liveTabActive: 1,
    };
  },
  computed: {},
  components: {
    HomeHead,
    Box,
    Boxtitle,
    Sevendayrecord,
    Proportionweather,
    Proportioncategories,
    Realdata,
    Temperaturetrend,
    Maxwindspeedtren,
    Minvisibilitytrend,
    CircleProgress,
  },

  created() {
    // 请求初始化数据
    this.fetch();
  },

  methods: {
    // 请求初始化数据
    fetch() {
      // 获取默认当前时间的七日气象记录
      this.getDefaultMeteoroListAndWeekday();
      // 获取默认当前时间到15天前的特殊天气占比
      this.getDefaultMeteororatio();
      // 获取默认当前时间到15天前的告警类别占比
      this.getDefaultMeteorotype();
      // 获取默认当前时间到15天前的温度趋势，最高风速趋势，最近能见度/最大波高趋势
      this.getDefaultMeteorovisibility();
    },

    // 获取默认当前时间的七日气象记录
    getDefaultMeteoroListAndWeekday() {
      const startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD"); //开始时间
      const endDate = dayjs().format("YYYY-MM-DD"); //结束时间
      const date = [startDate, endDate]; //起止时间
      // 获取七日气象记录
      this.getMeteoroListAndWeekday(date);
    },

    // 获取默认当前时间到15天前的特殊天气占比
    getDefaultMeteororatio() {
      const startDate = dayjs().subtract(15, "day").format("YYYY-MM-DD"); //开始时间
      const endDate = dayjs().format("YYYY-MM-DD"); //结束时间
      const date = [startDate, endDate]; //起止时间

      // 获取特殊天气占比
      this.changetimetwo(date);
    },

    // 获取默认当前时间到15天前的告警类别占比
    getDefaultMeteorotype() {
      const startDate = dayjs().subtract(15, "day").format("YYYY-MM-DD"); //开始时间
      const endDate = dayjs().format("YYYY-MM-DD"); //结束时间
      const date = [startDate, endDate]; //起止时间
      // 获取告警类别占比
      this.changetimethree(date);
    },

    // 获取默认当前时间到15天前的温度趋势，最高风速趋势，最近能见度/最大波高趋势
    getDefaultMeteorovisibility() {
      const startDate = dayjs().subtract(15, "day").format("YYYY-MM-DD"); //开始时间
      const endDate = dayjs().format("YYYY-MM-DD"); //结束时间
      const date = [startDate, endDate]; //起止时间
      // 获取温度趋势，最高风速趋势，最近能见度/最大波高趋势
      this.changetimefour(date);
    },

    // 获取七日气象记录
    getMeteoroListAndWeekday(data) {
      meteorolist({
        beginTime: dayjs(data[0]).format("YYYY-MM-DD"),
        endTime: dayjs(data[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        this.sevenddata = res.data;
      });
      meteoroweekday({
        beginTime: dayjs(data[0]).format("YYYY-MM-DD"),
        endTime: dayjs(data[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        this.dayvalue = res.data;
      });
      this.value1 = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
      this.sevendaytime = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
    },

    // 选择七日气象记录事件
    changetime(data) {
      var datediff = new Date(dayjs(data[1]).format("YYYY-MM-DD")).getTime() - new Date(dayjs(data[0]).format("YYYY-MM-DD")).getTime();
      var daydiff = Math.floor(datediff / (24 * 3600 * 1000));
      if (daydiff > 6) {
        ElMessage.error("所选时间不能超过七天,请重新选择时间");
        this.value1 = "";
        this.sevendaytime = "选择时间";
        return;
      }
      // 获取七日气象记录
      this.getMeteoroListAndWeekday(data);
    },

    changetimetwo(data) {
      meteororatio({
        beginTime: dayjs(data[0]).format("YYYY-MM-DD"),
        endTime: dayjs(data[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        this.weatherdata = res.data;
      });
      this.value2 = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
      this.Proportionweathertext = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
    },

    changetimethree(data) {
      meteorotype({
        beginTime: dayjs(data[0]).format("YYYY-MM-DD"),
        endTime: dayjs(data[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        const { data } = res;
        const object = new Object;
        if (data) {
          data.map((item) => {
            const { alertSubType, count } = item;
            if (alertSubType == 'TEMPERATURE' || alertSubType == 'WIND_SPEED' || alertSubType == 'HUMIDITY') {
              object[alertSubType] = count
            }
          })
          this.typedata = { ...{ TEMPERATURE: 0, WIND_SPEED: 0, HUMIDITY: 0 }, ...object };
        }
      });
      this.value3 = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
      this.Proportioncategoriestext = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
    },
    changetimefour(data) {
      meteorovisibility({
        beginTime: dayjs(data[0]).format("YYYY-MM-DD"),
        endTime: dayjs(data[1]).format("YYYY-MM-DD"),
      }).then((res) => {
        this.timedata = res.data;
      });
      this.value4 = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
      this.eventlogtimetext = dayjs(data[0]).format("YYYY-MM-DD") + " - " + dayjs(data[1]).format("YYYY-MM-DD");
    },
    onLiveTab(item, index) {
      const { id } = item;
      this.liveTabActive = id;
    },
    returnclick() {
      this.$router.push({ path: "/" });
    },
    goBack() {
      window.location.href = "about:blank";
      window.close();
    },
  },
};
</script>

<style lang="scss">
.Hydrometeor {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1920px;
  height: 1080px;
  background: #1f1f2b;
  z-index: 1;
}

.returnicon {
  width: 0.09rem;
  height: 0.09rem;
  background: url("../../assets/icons/returnbtn.png") no-repeat;
  background-size: 100%;
  display: inline-block;
  position: relative;
  top: 3px;
}

.back_row {
  margin-top: 70px;
  padding: 0 20px;
  position: relative;
  z-index: 1000;
}

.EventBox {
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  transform-origin: 0% 0%;
  transition: transform 0.2s linear;
}

.eventlogalltime {
  background: #5b657f;
  width: 100% !important;
  height: 40px;
  color: #fff;
  border-radius: 4px;
  line-height: 30px;
  margin-bottom: 10px;
}

.eventlogalltime .el-input__icon {
  display: none;
}

.eventlogalltime .el-range-separator {
  display: none;
}

.eventlogalltime .el-range-input {
  background: #5b657f;
  text-align: left;
}

.eventlogalltime .el-range-input:nth-child(2) {
  display: none;
}

.Hydrometeor {
  .navigation {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
  }

  .navigation-btn {
    width: 140px;
    height: 44px;
    background: rgba(95, 107, 138, 0.7);
    border-radius: 4px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    margin-right: 15px;
    color: #fff;
    transition: background 0.2s ease-out;

    &:hover {
      // border: #fff 2px solid;
      background: rgba(95, 107, 138, 1);
    }

    &:active {
      opacity: 0.5;
    }
  }

  .navigation-btn-text {}

  .active {
    background: rgba(28, 117, 182, 0.7);

    &:hover {
      // border: #fff 2px solid;
      background: rgba(28, 117, 182, 0.7);
    }
  }
}

.live_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;

  .live_tab {
    width: auto;

    .live_tab_item {
      width: auto;
      padding: 0 10px;
      margin: 0 5px;
      color: #1892f8;
      border: #1892f8 2px solid;
      font-weight: bold;
      cursor: pointer;
    }

    .live_tab_active {
      color: #ffffff;
      border: #dfdfdf 2px solid;
      background-color: #5b657f;
    }
  }
}
</style>

<template>
  <div class="Temperaturetrend">
    <apexchart
      type="line"
      height="230"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
export default {
  name: "Hydrometeor",
  data() {
    return {
      series: [
        {
          name: "最低温度",
          data: [],
        },
        {
          name: "最高温度",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 230,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          range: 7 - 1,
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: true,
          },
          categories: [],
        },
        colors: ["#127EED", "#EE3C33"],
      },
    };
  },
  computed: {},
  components: {},
  props: ["datevalue"],
  watch: {
    datevalue(newName, oldName) {
      let max = [];
      let min = [];
      let categories = [];
      newName.forEach((element) => {
        min.push(element.minTemperature);
        max.push(element.maxTemperature);
        categories.push(element.statisticsDate);
      });
      this.series = [
        {
          name: "最低温度",
          data: min,
        },
        {
          name: "最高温度",
          data: max,
        },
      ];
      this.chartOptions = {
        xaxis: {
          categories,
        },
      };
    },
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<!--
 * @Author: PC
 * @Date: 2022-01-04 15:34:32
 * @LastEditTime: 2022-04-22 12:33:54
 * @Description: 请您输入文件描述
-->
<template>
  <div class="boxtitle">
    <!-- <div class="main_box"> -->
    <slot></slot>
    <!-- </div> -->
  </div>
</template>

<script></script>

<style>
.boxtitle {
  width: 100%;
  background: #2a2e3a;
  position: relative;
  float: left;
  z-index: 999;
}
.boxtitle::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #4d79f5;
  left: 20px;
  top: 15px;
}
.boxtitle div {
  width: 93%;
  height: 100%;
  float: right;
  line-height: 40px;
}
.boxtitle h4 {
  float: left;
  font-size: 16px;
  color: #fff;
  margin-left: 15px;
}
.boxtitle .el-range-editor.el-input__inner {
  width: 170px;
  height: 30px;
  background: #5b657f;
  border: none;
  color: #fff !important;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px;
  margin-right: 10px;
}
.boxtitle .el-range-input {
  width: 160px;
  height: 30px;
  background: none;
  border: none;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  margin-top: 0px;
  font-size: 14px;
  margin-left: -5px;
}
</style>
